// Import Google font
@import url("http://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

// Import all styles
@import "margins.less";
@import "alerts.less";
@import "modal.less";
@import "progress_bar.less";
@import "login.less";
@import "timeline.less";
@import "contact.less";
@import "calendar.less";
@import "text_editor.less";
@import "grid.less";
@import "dropdown.less";
@import "chat.less";
@import "buttons.less";
@import "fonts.less";
@import "icheck.less";
@import "layout.less";
@import "lists.less";
@import "panels.less";
@import "tabs.less";
@import "tooltip.less";
@import "various.less";
@import "projects.less";
@import "social_board.less";
@import "nestable_list.less";
@import "tour.less";
@import "blog.less";
@import "forum.less";
@import "mailbox.less";
@import "loader.less";

// Styles for landing page
@import "landing.less";
