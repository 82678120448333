// Import mixins & variables
@import "various.less";

/* Progress bar */

.progress {
  border-radius: 2px;
  margin-bottom: 10px;
}

.progress-bar {
  background-color: @color-background;
  text-align: right;
  padding-right: 10px;
  color: @color-text;
}

.progress-small, .progress-small .progress-bar {
  height: 10px;
}

.progress-bar-success {
  border-right: 4px solid darken(@color-green, 5%);
}

.progress-bar-info {
  border-right: 4px solid darken(@color-blue, 5%);
}

.progress-bar-warning {
  border-right: 4px solid darken(@color-yellow, 5%);
}

.progress-bar-danger {
  border-right: 4px solid darken(@color-red, 5%);
}

.full .progress-bar-success {
  background-color:  @color-green;
  border-right: 4px solid darken(@color-green, 5%);
}

.full .progress-bar-info {
  background-color:  @color-blue;
  border-right: 4px solid darken(@color-blue, 5%);
}

.full .progress-bar-warning {
  background-color:  @color-yellow;
  border-right: 4px solid darken(@color-yellow, 5%);
}

.full .progress-bar-danger {
  background-color:  @color-red;
  border-right: 4px solid darken(@color-red, 5%);
}

.full .progress-bar {
  color: #ffffff;
}
