// Import mixins & variables
@import "various.less";

// tooltip
.tooltip-inner {
  background-color: @color-navy-blue;
}

.tooltip.top .tooltip-arrow {
  border-top-color: @color-navy-blue;
}
.tooltip.right .tooltip-arrow {
  border-right-color: @color-navy-blue;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: @color-navy-blue;
}
.tooltip.left .tooltip-arrow {
  border-left-color: @color-navy-blue;
}
