// Import mixins & variables
@import "various.less";

.note-editor {
  border: 1px solid @border-color;
}
.note-editor .note-toolbar {
  border-bottom: 1px solid @border-color;
}

.note-editor .btn-default {
  border-color: @border-color;
}
