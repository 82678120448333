// Import mixins & variables
@import "various.less";

.forum-box {

  .panel-body {
    border-bottom: none;
  }

  .panel-body:last-child {
    border-bottom: 1px solid @border-color;
  }

  .forum-heading {
    h4 {
      margin-bottom: 3px;
      font-weight: 600;
      margin-top: 5px;
      display: inline;
    }

    .label {
      margin-right: 5px;
    }

    .desc {
      color: lighten(@color-text, 15%);
      font-size: 12px;
    }
  }

  .panel-heading {
    border: 1px solid @border-color;
    border-bottom: none;
    font-size: 90%;
    background: @color-bright;
    padding: 10px 20px;
  }

  .forum-info {
    text-align: right;
    line-height: 20px;

    .number {
      display: block;
      font-weight: 600;
      font-size: 22px;
      margin-top: 5px;
    }

    span {
      font-size: 12px;
    }
  }

  .panel-body .media-image {
    text-align: center;
    margin-right: 10px;
    min-width: 100px;
  }

  .panel-body .media img {
    margin-top: 10px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .author-info {
    font-size: 12px;
  }

  .forum-comments {
    border: 1px solid @border-color;
    border-left: none;
    border-right: none;
    background: @color-bright;
    margin-top: 20px;
    padding: 10px 15px;
  }

  .forum-comments .media img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .forum-comments form {
    margin-top: 10px;
  }

}

@media(max-width: 768px) {

  .forum-box .forum-info {
    text-align: left;
  }

}


