// Import mixins & variables
@import "various.less";

.sweet-alert button {
  background-color: #34495e;
  color: white;
  border: none;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 26px 5px 0 5px;
  cursor: pointer;
}

.sweet-alert button:focus {
  outline: none;
  box-shadow: 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.sweet-alert button:hover {
  background-color: #a1d9f2;
}

.sweet-alert button:active {
  background-color: #81ccee;
}

.sweet-alert button.cancel {
  background-color: #D0D0D0;
}

.sweet-alert button.cancel:hover {
  background-color: #c8c8c8;
}

.sweet-alert button.cancel:active {
  background-color: #b6b6b6;
}
.sweet-alert p {
  font-size: 14px;
  font-weight: 400;
}

.sweet-alert h2 {
  font-size: 26px;
}

.sweet-alert {
  padding: 25px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow : 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  -moz-box-shadow : 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2)
}

.table > thead > tr > th {
  border-bottom: 1px solid #ddd;
}

.cg-notify-message.homer-notify {
  background: #fff;
  padding: 0;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow : 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  -moz-box-shadow : 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  border:none ;
  margin-top: 30px;
  color: inherit;
}

.homer-notify.alert-warning {
  border-left: 6px solid @color-yellow;
}
.homer-notify.alert-success {
  border-left: 6px solid @color-green;
}

.homer-notify.alert-danger {
  border-left: 6px solid @color-red-deep;
}

.homer-notify.alert-info {
  border-left: 6px solid @color-blue;
}
