#side-menu {
    > li > a > .arrow {
        margin-top: .3em;
    }
}

#title-description{
    padding-top: .5em;
    float: left;

    span{
        font-size: 2em;
        color: #3498db;
    }
}

.navbar-right{
    i.ifont{
        font-size: 1em;
    }
}

ul#side-menu{
    i.ifont, i.fa{
        font-size: 1.2em;
        margin-right: .3em;
        vertical-align: middle;
    }
}
