// Import variables
@import "variables.less";

/* Opacity */
.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 1;
}

/* Google Maps */
.google-map {
  height: 300px;
}

.hr-line-dashed {
  background-color: transparent;
  border-top: 1px dotted @color-background;
  color: transparent;
  height: 1px;
  margin: 20px 0;
}

/* Mixin */
.bg-transparent(@color, @alpha: 1) {
  background: rgba( red(@color), green(@color), blue(@color), @alpha);
}

.color-transparent(@color, @alpha: 1) {
  color: rgba( red(@color), green(@color), blue(@color), @alpha);
}

.animated-panel {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* Color */

.h-bg-navy-blue {
  background: @color-navy-blue;
}
.h-bg-violet {
  background: @color-violet;
}
.h-bg-blue {
  background: @color-blue;
}
.h-bg-green {
  background: @color-green;
}
.h-bg-yellow {
  background: @color-yellow;
}
.h-bg-orange {
  background: @color-orange;
}
.h-bg-red {
  background: @color-red;
}
.h-bg-red-deep {
  background: @color-red-deep;
}

.text-primary {
  color: @color-navy-blue;
}
.text-primary-2 {
  color: @color-violet;
}
.text-info {
  color: @color-blue;
}
.text-success {
  color: @color-green;
}
.text-warning {
  color: @color-yellow;
}
.text-danger {
  color: @color-red;
}
.text-white {
  color: #fff;
}

.border-top {
  border-top: 1px solid @border-color;
}
.border-bottom {
  border-bottom: 1px solid @border-color;
}
.border-right {
  border-right: 1px solid @border-color;
}
.border-left {
  border-left: 1px solid @border-color;
}

.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* Inputs */

.has-warning .form-control,
.has-error .form-control,
.has-success .form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.has-error .form-control {
  border-color: darken(@color-red, 10%) !important;
}

.has-warning .form-control {
  border-color: darken(@color-yellow, 10%) !important
}

.has-success .form-control {
  border-color: darken(@color-green, 10%) !important
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: darken(@color-red, 10%);
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
  color: darken(@color-yellow, 10%);
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
  color: darken(@color-green, 10%);
}
/* Backgrounds */
.bg-white {
  background-color: #fff;
}

.bg-light-soft {
  background-color: @color-background;
}

.bg-light {
  background-color: @color-bright;
}

/* Slider */
#logo {
  float: left;
  width: @menu-width;
  background-color: @color-navy-blue;
  padding: 18px 10px 18px 18px;
  height: 56px;
  text-align: center;
}

#logo span {
  font-weight: 600;
  color: #fff;
  font-size: 14px;
}

#logo.light-version {
  background-color: @color-bright;
  border-bottom: 1px solid @border-color;
  text-align: center;
}

#logo.light-version span {
  font-weight: 600;
  color: @color-navy-blue;
  font-size: 14px;
}

.profile-picture {
  padding: 20px 20px;
  text-align: center;
}

.stats-box div {
  display:inline-block;
  vertical-align:middle;
}

.stats-label {
  line-height: 1.2;
}

.stats-label small {
  font-size: 80%;
  line-height: 0.6;
}

.stats-box-value {
  background: lighten(@color-bright, 4%);
}

.stats-box-detail {
  background: @color-navy-blue;
  color: #fff;
  position: relative;
}

/* Labels */
.label-menu-corner .label {
  font-size: 10px;
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: 18px;
  top: 12px;
}

/* Menu */
#side-menu {
  background: @color-bright;
}

#side-menu li a {
  color: @color-text;
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px 20px;
}

#side-menu li {
  border-bottom: 1px solid @border-color;
}

#side-menu li:first-child {
  border-top: 1px solid @border-color;
}

#side-menu li.active a, #side-menu li:hover a {
  color: @color-navy-blue;
}

#side-menu li .nav-second-level li a {
  padding: 10px 10px 10px 30px;
  color: @color-text;
  text-transform: none;
  font-weight: 600;
}

#side-menu li .nav-second-level li.active a {
  color: @color-navy-blue;
  background-color: @color-bright;
}

#side-menu.nav > li > a:hover, #side-menu.nav > li > a:focus {
  background: #fff;
}

#side-menu li.active {
  background: #fff;
}

.arrow {
  float: right;
}
.fa.arrow:before {
  content: "\f104";
}

.active > a > .fa.arrow:before {
  content: "\f107";
}

#top-navigation {
  height: 56px;
  position: relative;
  float: left;
}

.navbar-static-top  {
  margin: 0 0 0 @menu-width;
}

.navbar.navbar-static-top a, .nav.navbar-nav li a {
  color: lighten(@color-text, 20%);
}

.header-link {
  padding: 18px 26px 17px 26px;
  font-size: 14px;
  float: left;
  border-right:1px solid @border-color;
  border-left:1px solid @border-color;
  margin-right: 15px;
  background: @color-bright;
  cursor: pointer;
}

.header-link:hover {
  background: darken(@color-bright,2%);
}


.navbar-nav > li,.navbar-nav > li > a {
  height: 56px;
}

.navbar-nav > li > a {
  padding: 15px 20px;
  font-size: 25px;
}

.navbar-nav > li:last-child > a {
  padding-right: 35px;
}

.navbar.navbar-static-top .hide-menu {
  color: lighten(@color-text, 20%);
}



.panel-title {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

/* Box */
.box {
  padding: 40px;
}

.box-rb-border {
  border-right: 1px solid @border-color;
  border-bottom: 1px solid @border-color;
}

.box-b-border {
  border-bottom: 1px solid @border-color;
}

.box-r-border {
  border-right: 1px solid @border-color;
}

/* Height */

.h-100 {
  min-height: 100px;
}
.h-150 {
  min-height: 150px;
}
.h-200 {
  min-height: 200px;
}
.h-300 {
  min-height: 300px;
}
.h-400 {
  min-height: 400px;
}
.h-500 {
  min-height: 500px;
}
.h-600 {
  min-height: 600px;
}

/* Sidebar Hide */

body.hide-sidebar #menu {
  margin-left: -@menu-width;
}

body.hide-sidebar #wrapper {
  margin-left: 0;
}

body.page-small #menu {
  margin-left: -@menu-width;
}

body.page-small #wrapper {
  margin-left: 0;
}

body.page-small.show-sidebar #menu {
  margin-left: 0;
}

body.page-small.show-sidebar #wrapper {
  margin-left: @menu-width;
}

a {
  cursor: pointer;
  color: @color-navy-blue;
}

a:hover, a:focus {
  color: @color-navy-blue;
  text-decoration: none;
}

.no-padding {
  padding: 0 !important;
}
.no-borders {
  border: none !important;
}
.no-margins {
  margin: 0 !important;
}

.no-background {
  background: transparent !important;
}

.navbar-form-custom {
  float: left;
  height: 52px;
  padding: 0;
  width: 180px;
}

.navbar-form-custom .form-control {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  font-size: 13px;
  height: 52px;
  margin: 0;
  z-index: 1060;
  padding: 0;
}

.form-control, .form-control:focus, .has-error .form-control:focus, .has-success .form-control:focus, .has-warning .form-control:focus, .navbar-collapse, .navbar-form, .navbar-form-custom .form-control:focus, .navbar-form-custom .form-control:hover, .open .btn.dropdown-toggle, .panel, .popover, .progress, .progress-bar {
  box-shadow: none;
}

.vertical-mid {
  display: table;
}

.vertical-mid * {
  display: table-cell;
  vertical-align: middle;
}
/* Labels */

.label-success {
  background-color: @color-green;
}

/* LIST GROUP */

a.list-group-item.active, a.list-group-item.active:hover, a.list-group-item.active:focus {
  background-color: @color-navy-blue;
  border-color: @color-navy-blue;
  color: #FFFFFF !important;
}

.list-group-item-heading {
  margin-top: 10px;
}

.list-group-item-text {
  margin: 0 0 10px;
  color: inherit;
  line-height: inherit;
}

.list-group-item {
  background-color: inherit;
  border: 1px solid @border-color;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;
}

.no-padding .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-padding .list-group-item:first-child {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
}

.no-padding .list-group {
  margin-bottom: 0;
}


.well {
  background: @color-bright;
  box-shadow: none;
}

/* Stats */

.stats h4, h5, h6 {
  margin: 5px 0;
}

.stats-title h4{
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

/* Widths */

.w-xs { min-width: 100px; }
.w-sm { min-width: 120px; }
.w-m { min-width: 140px; }
.w-lg { min-width: 160px; }
.w-xl { min-width: 180px; }
.w-xxl { min-width: 200px; }


/* Charts */
.flot-chart {
  display: block;
  height: 300px;
  position: relative;
}

.flot-chart-content {
  width: 100%;
  height: 100%;
}

.stats .flot-chart {
  height: 60px;
}

/* Messages */
.feed-activity-list .feed-element {
  border-bottom: 1px solid #E7EAEC;
}
.feed-element:first-child {
  margin-top: 0;
}
.feed-element {
  padding-bottom: 15px;
}
.feed-element, .feed-element .media {
  margin-top: 15px;
}
.feed-element, .media-body {
  overflow: hidden;
}
.feed-element > .pull-left {
  margin-right: 10px;
}

.feed-element img.img-circle, .dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}

.feed-element .well {
  border: 1px solid #E7EAEC;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}

.feed-element .actions {
  margin-top: 10px;
}

.feed-element .photos {
  margin: 10px 0;

}

.feed-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}

.feed-element img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

.small-logo {
  display: none;
  text-align: center;
  padding-top: 18px;
  font-size: 14px;
  font-weight: 800;
}

.img-small {
  width:62px;
  height: 62px;
}

@media(max-width:768px) {

  .navbar-form-custom, .navbar-right, #logo {
    display: none;
  }

  .navbar-static-top {
    margin: 0;
  }

  .small-logo {
    display: block;
    float: left;
  }

  // Demo only
  .header-line div {
    display: none;
  }

  .color-demo .header-line
  {
    height: 48px;
  }

  #hbreadcrumb {
    float: none !important;
    margin-bottom: 10px;
    margin-top: 0;
  }

}

@media(max-width: 320px) {
  #wrapper {
    overflow: hidden;
  }
}

.canvass {
  -moz-transition:width 0.01s, height 0.01s;
  -webkit-transition:width 0.01s, height 0.01s;
  -o-transition:width 0.01s, height 0.01s;
  transition:width 0.01s, height 0.01s;
}


.project-list table tr td {
  border-top: none;
  border-bottom: 1px solid #e7eaec;
  padding: 10px 10px;
  vertical-align: middle;
}

.icons-box {
  padding: 20px;
}

.icons-box h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.icons-box .infont {
  text-align: center;
  margin-bottom: 25px;
}

.icons-box .infont i {
  font-size: 35px;
  display: block;
  color: #676a6c;
}
.icons-box .infont span {
  color: #a6a8a9;
}
.icons-box .infont span {
  margin: 1px;
  display: block;
}

.fullpanel {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1070;
  overflow-x: scroll;
  transition: all 2s;
}

.transition {
  transition: all 0.5s;
}

.normalheader  .hpanel {
  margin-bottom: 0;
}

.normalheader {
  min-width: 320px;
  padding: 40px 40px 0px 40px;
}

@media(max-width: 768px) {

  .normalheader {
    padding: 15px 15px 0px 15px;
  }

}

.normalheader h2 {
  margin-top: 0px;
}

.input-xs {
  height: 20px;
  padding: 2px 10px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 3px;
  font-weight: 500;
}

.clip-header i {
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.clip-header {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 8px;
  background: @color-bright;
  padding: 4px 5px;
  border-left: 1px solid @border-color;
  border-bottom: 1px solid @border-color;
}

.dropdown-menu {
  font-size: 13px;
  border-radius: 3px;
}

.dropdown-example {
  display: inline-block;
  margin: 0px 10px 10px 10px;
}

.dropdown-example .dropdown-menu {
  position: static;
  display: block;

}

.pagination-example .pagination {
  margin: 0 0 10px 0;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: @color-blue;
  border-color: darken(@color-blue, 5%);
  color: #fff;
  cursor: default;
  z-index: 2;
}

.hbreadcrumb a.active {
  color: #9d9fa2;
}

.form-control {
  border-color: @border-color !important;
}

.input-group-addon {
  background: lighten(@color-background, 3%);
  border-color: @border-color !important;
}

.sidebar-button {
  padding: 4px 10px 0 10px;

  i {
    font-size: 20px;
  }
}

/* Splash style */

.splash {
  position: fixed;
  z-index: 2000;
  background: white;
  color: gray;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.splash-title {
  text-align: center;
  max-width: 500px;
  margin: 15% auto;
  padding: 20px;
}

.splash-title h1 {
  font-size: 26px;
}

/* Checkbox styles */

.checkbox-variant(@parent, @color) {
  .@{parent} input[type="checkbox"]:checked + label {
    &::before {
      background-color: @color;
      border-color: @color;
    }
    &::after {
      color: #fff;
    }
  }
}

.radio-variant(@parent, @color) {
  .@{parent} input[type="radio"]{
    & + label{
      &::after{
        background-color: @color;
      }
    }
    &:checked + label{
      &::before {
        border-color: @color;
      }
      &::after{
        background-color: @color;
      }
    }
  }
}

.checkbox-variant(checkbox-primary, @color-navy-blue);
.checkbox-variant(checkbox-danger, @color-red);
.checkbox-variant(checkbox-info, @color-blue);
.checkbox-variant(checkbox-warning, @color-yellow);
.checkbox-variant(checkbox-success, @color-green);
.radio-variant(radio-primary, @color-navy-blue);
.radio-variant(radio-danger, @color-red);
.radio-variant(radio-info, @color-blue);
.radio-variant(radio-warning, @color-yellow);
.radio-variant(radio-success, @color-green);

.checkbox input:hover,
.radio input:hover {
  cursor: pointer;
}

/* UI Sortable */
.ui-sortable .panel-body {
  cursor: move;
}
.ui-sortable-placeholder {
  border: 1px dashed #cecece !important;
  visibility: visible !important;
  background: #f7f9fa;
}
.ibox.ui-sortable-placeholder {
  margin: 0px 0px 23px !important;
}

/* Badge colors */

.badge.badge-success {
  background-color: @color-green;
}

.badge.badge-info {
  background-color: @color-blue;
}

.badge.badge-danger {
  background-color: @color-red;
}

.badge.badge-warning {
  background-color: @color-yellow;
}

.badge.badge-primary {
  background-color: @color-navy-blue;
}

/* Tables */

.table > thead > tr > th {
  border-bottom: none;
}

.no-padding table {
  margin-bottom: 0;
}

/* Validation */

.form-control.error {
  border-color: @color-red !important;
}

label.error, label span.error {
  color: @color-red;
  margin: 5px 0 0 0;
  font-weight: 400;

}

label span.error {
  margin-left: 5px;
}

label.error:before {
  content: "\f071";
  font-family: 'FontAwesome';
  margin: 0 4px;
}


/* File manager */

.hpanel .panel-body.file-body {
  padding: 30px 0;
  text-align: center;
}

.file-body i.fa {
  font-size: 56px;
}

/* Filter search */
.filter-item {
  margin-bottom: 0;

  .panel-body {
    padding: 10px 20px;
    border-bottom: none;
  }

  a {
    color: @color-text;
  }

}

.filter-item:last-child {
  border-bottom: 1px solid @border-color;
}

.filter-item:hover, .filter-item.active {

  .panel-body {
    background: #fafcfd;
    z-index: 100;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, .175);
    -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, .175);
    box-shadow: 0 2px 3px rgba(0, 0, 0, .175);
  }
}

.filter-item::selection {
  .panel-body {
    background: #fafcfd;
  }
}

.filter-item h4 {
  margin-bottom: 5px;
}

/* Big menu */

.hdropdown.bigmenu {
  padding: 30px 30px 0px 30px;
}

.bigmenu a i {
  font-size: 46px;
}

.bigmenu a {
  display: block;
  text-align: center;
  margin: 0 10px 30px 0;
  min-width: 100px;
}

.bigmenu h5 {
  font-size: 13px;
  margin-top: 10px;
}

/* Notes page */
@media (min-width: 991px)
{
  #notes
  {
    display: block;
    height: auto !important;
    visibility: visible;
  }

}

#notes .panel-body:first-child {
  border-top: none;
}

.note-content textarea {
  border: none;
  width: 100%;
  padding: 0;
  min-height: 360px;
}

.note-link {

  h5 {
    margin-top: 0;
  }

  &:hover {
    background: @color-bright;
  }

}

/* App Plans */

.plan-box {
  -ms-transform: scale(0.85);
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  opacity: 0.7;
  transition: all 0.2s ease 0s;

}

.plan-box.active, .plan-box:hover {
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Mobile navbar */

.mobile-menu {



}

@media (max-width: 768px) {
  .mobile-menu-toggle {
    display: block;
  }

  .mobile-menu {
    .navbar-nav > li, .navbar-nav {
      float: none;
    }
  }
}

.mobile-navbar {
  position: absolute;
  top: 62px;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 100;

  .navbar-nav {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid @border-color;
  }

  .navbar-nav > li > a {
    padding: 15px 15px;
    font-size: 14px;
    color: @color-text;
  }

  .navbar-nav > li > a:hover, .navbar-nav > li > a:focus, .navbar-nav .open > a, .navbar-nav .open > a:hover, .navbar-nav .open > a:focus {
    border-bottom: none;
  }

}

@media (min-width: 769px) {
  .mobile-navbar, .mobile-navbar.collapse.in {
    display: none;
  }


}

/* CodeMirror */
.CodeMirror {
  border: 1px solid #eee;
  height: auto;
}
.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}


/* Fix for xeditable and chekcbox plugin in angularJS version */
.x-edit-checkbox .checkbox label::before {
  display: none;
}

/* Toastr custom styles*/
#toast-container {
}

#toast-container.toast-bottom-center > div, #toast-container.toast-top-center > div {
  margin: 10px auto 0;
}

#toast-container > .toast-info,
#toast-container > .toast-error,
#toast-container > .toast-warning,
#toast-container > .toast-success{
  background-image: none;
}

#toast-container > div {
  background: #fff;
  padding: 20px;
  color: #6a6c6f;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 1;
}


#toast-container > div:hover {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
}

.toast-close-button {
  color: #000;
  opacity: 0.2;
}

.toast-info {
  background: #fff;
  border-left: 6px solid #3498db;
}
.toast-success {
  background: #fff;
  border-left: 6px solid #62cb31;
}
.toast-warning {
  background: #fff;
  border-left: 6px solid #ffb606;
}
.toast-error {
  background: #fff;
  border-left: 6px solid #e74c3c;
}

.toast-progress {
  opacity: 0.6;
}

.toast-info .toast-progress {
  background-color: #3498db;
}
.toast-success .toast-progress {
  background-color: #62cb31;
}
.toast-warning .toast-progress {
  background-color: #ffb606;
}
.toast-error .toast-progress {
  background-color: #e74c3c;
}

/* Nestable list */

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  border: 1px solid #e4e5e7;
  background: #f7f9fa;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-handle span {
  font-weight: bold;
}

.dd-handle:hover {
  background: #f0f0f0;
  cursor: pointer;
  font-weight: bold;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}

.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action="collapse"]:before {
  content: '-';
}

#nestable2 .dd-item > button {
  font-family: FontAwesome;
  height: 34px;
  width: 33px;
  color: #c1c1c1;

}

#nestable2 .dd-item > button:before {
  content: "\f067";
}

#nestable2 .dd-item > button[data-action="collapse"]:before {
  content: "\f068";
}

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #e4e5e7;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  z-index: 9999;
  pointer-events: none;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
}

/**
* Nestable Extras
*/
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #e4e5e7;
  border-bottom: 2px solid #e4e5e7;
}

#nestable-menu {
  padding: 0;
  margin: 10px 0 20px 0;
}

#nestable-output,
#nestable2-output {
  width: 100%;
  font-size: 0.75em;
  line-height: 1.333333em;
  font-family: open sans, lucida grande, lucida sans unicode, helvetica, arial, sans-serif;
  padding: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#nestable2 .dd-handle {
  color: inherit;
  border: 1px dashed #e4e5e7;
  background: #f7f9fa;
  padding: 10px;
}

#nestable2 .dd-handle:hover {
  /*background: #bbb;*/
}


#nestable2  span.label {
  margin-right: 10px;
}

#nestable-output,
#nestable2-output {
  font-size: 12px;
  padding: 25px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

