@import "variables.less";
@import "../homer/variables.less";

// Styles Typo

.bold{
    font-weight: bold;
}

.sub-title{
    color: #e30613;
}

// Colors

.red-color{
    color: @red-color;
}

.green-color{
    color: @color-green;
}

.orange-color{
    color: @color-orange;
}

.navy-blue-color{
    color: @color-navy-blue;
}

// Datatables

.dataTables_wrapper{
    overflow-y: hidden;

    .row{
        margin-left: 0;
        margin-right: 0;
    }

    .table-bordered{
        > thead{
            tr[role="row"]{
                background-color: #c0392b;
                color: #fff;
            }
            > tr > td{
                border-bottom: none;
            }
            input, select{
                height: 25px;
            }
            select{
                padding: 3px 12px !important;
            }
            th[aria-label="Actions"]{
                min-width: 65px;
            }
            tr:first-child th input{
                height: inherit;
            }
        }
    }

    table.dataTable{
        td{
            a[role="button"]:not(:last-child){
                margin-right: .5em;
            }
        }
    }
}

div.dataTables_wrapper div.dataTables_filter {
    display: none;
}

div.dataTables_wrapper div.dataTables_filter input {
    width: 70%;
}

.dataTables_wrapper .table-bordered > thead input, .dataTables_wrapper .table-bordered > thead select {
    font-size: 11px;
}

.dataTables_caption{
    font-weight: bold;
    font-size: 1.1em;
    padding-top: 8px;
    padding-bottom: 1px;
    color: #777777;
    text-align: left;
    display: table-caption;
    width: 43.5em;
}
.dataTable.totalCommand {
    td {
        font-weight: bold;
    }
}

#invoice_cost_datatable_wrapper, #invoice_requested_datatable_wrapper{
    .col-sm-12, .col-sm-7{
        padding-left: 0;
        padding-right: 0;
    }
}

#total-amount-order-view, #total-amount-offer-view, #total-amount-site-view{
    float: right;
    font-size: 1.1em;
    font-weight: bold;
    margin-top: .5em;
}

// Tables

.table-responsive{
    table{
        caption{
            font-weight: bold;
            font-size: 1.1em;
        }

        tr{
            th{
                background-color: #c0392b;
                color: #fff;
            }

            th.no-left-border{
                background-color: transparent;
                border-top: 1px solid white !important;
                border-left: 1px solid white !important;
            }

            th.no-right-border{
                background-color: transparent;
                border-top: 1px solid white !important;
                border-right: 1px solid white !important;
            }
        }

        td.not-important-day{
            background-color: #feeaeb;
        }
    }
}

.table > thead > tr > td {
    padding: 5px 8px 5px 8px;
}

.multiselect{
    text-align: center;
    padding-top: 8em;

    #contact_datatable_multiselect_actions{
        margin-bottom: 1.5em;
    }

    .btn.pull-right{
        float: none !important;
    }
}

.table-form{
    td{
        vertical-align: middle !important;
        padding: 5px 8px 5px 8px !important;
    }

    .form-control{
        height: 25px;
        padding: 3px 10px;
    }

    .form-control{
        font-size: 11px;
    }

    .input-group-addon{
        padding: 4px 12px;
    }

    button{
        padding: 2px 7px;
    }
}

.extern-real-costs-table{
    form{
        .form-group{
            width: 60%;
            margin-bottom: 0;

            input{
                font-size: 13px;
                height: 28px;
            }
        }
        .btn{
            vertical-align: top;
            line-height: .9;
        }
    }
    td{
        padding: 3px 8px !important;
        vertical-align: middle !important;
    }
}

// Datepicker

input::-webkit-calendar-picker-indicator{
    display: none;
}

input[type="date"]::-webkit-input-placeholder{
    visibility: hidden !important;
}

// Calendar

.fc-basic-view .fc-body .fc-content {
    cursor: pointer;
}

// Icheck

.icheckbox_square-green,
.iradio_square-green{
    background-image: url('/assets/css/images/icheck/skins/square/green.png');
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (-moz-min-device-pixel-ratio: 1.5),
       only screen and (-o-min-device-pixel-ratio: 1.5),
       only screen and (min-device-pixel-ratio: 1.5) {
    .icheckbox_square-green,
    .iradio_square-green{
        background-image: url('/assets/css/images/icheck/skins/square/green@2x.png');
    }
}

.checkbox label, .radio label {
    padding-left: 0;
    font-weight: bold;
}

// Colorpicker

.colorpicker-saturation {
    background-image: url('/assets/css/images/bootstrap-colorpicker/dist/img/bootstrap-colorpicker/saturation.png');
}

.colorpicker-hue {
    background-image: url('/assets/css/images/bootstrap-colorpicker/dist/img/bootstrap-colorpicker/hue.png');
}

.colorpicker-alpha {
    background-image: url('/assets/css/images/bootstrap-colorpicker/dist/img/bootstrap-colorpicker/alpha.png');
}

.colorpicker-color {
    background-image: url('/assets/css/images/bootstrap-colorpicker/dist/img/bootstrap-colorpicker/alpha.png');
}


// Panels

.hpanel{
    .admin-dashboard-panel{
        min-height: 310px;
    }
}


// Summernote

@font-face {
    font-family: 'summernote';
    src: url('../fonts/summernote.eot') format('embedded-opentype'), url('../fonts/summernote.woff') format('woff'), url('../fonts/summernote.ttf') format('truetype');
}

.note-editor.note-frame {
    border: 1px solid #e4e5e7;
}

a {
    cursor: pointer;
    color: @color-blue;
}

// Form

.invisible-input{
    border: 1px solid transparent !important;
    background-color: #fff !important;
    width: 7em !important;

    + .input-group-addon{
        display: none;
    }
}

.form-inline{
    > .form-group{
        margin-bottom: 15px;

        label{
            margin-right: .5em;
        }
    }
}

.radio{
    margin-right: 1em;
}

// Graphs

.c3-target-Personnel, .c3-target-Matériel-Heure, .c3-target-Matériel-Jour, .c3-target-Frais-de-Chantier, .c3-target-CRH{
    opacity: 0 !important;
}

.last-tr{
    border-bottom: 2px solid grey;
}
